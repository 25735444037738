// you can import modules from the theme lib or even from
// NPM packages if they support it…

require('./utils/doc-ready')
//const ContactForm = require('./components/_contact');
const NavComponent = require('./components/_nav');

//ContactForm.init();
NavComponent.init();

