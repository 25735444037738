module.exports = {
    init: function () {
                const component = document.getElementById("navToggler");
                const navContainer = document.getElementById("navContainer");
                const closeNav = document.getElementById('closeNav');

                component.addEventListener('click', () => {
                    navContainer.classList.toggle('hidden');
                });
                closeNav.addEventListener('click', () => {
                    navContainer.classList.toggle('hidden');
                });
                if (window.innerWidth < 1279) {
                    var hasChildren = document.querySelector('#navContainer .menu-item-has-children');
                    if(hasChildren){
                        var SubmenuItems = document.querySelector('#navContainer .menu-item-has-children .sub-menu');
                        hasChildren.addEventListener('click', () => {
                            hasChildren.querySelector('.sub-menu').classList.toggle("show");
                        });
                    }
                }
                document.querySelectorAll('.mobile-nav-wrapper .menu-item').forEach(item => {
                    item.addEventListener('click', () => {
                        if(!item.classList.contains("menu-item-has-children")){
                            navContainer.classList.toggle('hidden');
                        }

                    });
                });

                let scrollpos = window.scrollY;
                const header = document.querySelector(".header-wrapper");

                const add_class_on_scroll = () => header.classList.add("show-scroll-menu");
                const remove_class_on_scroll = () => header.classList.remove("show-scroll-menu");

                window.addEventListener('scroll', function() {
                    scrollpos = window.scrollY;

                    if (scrollpos >= 1) {
                        add_class_on_scroll();
                        document.querySelector('#Menu').setAttribute('fill', '#fff');
                    } else {
                        remove_class_on_scroll();
                        document.querySelector('#Menu').setAttribute('fill', '#333');
                    }
                });
                return {
                    init: init
                };

        function init() {
        }
    }
}